<template>
  <div :class="$bem()">
    <e-input
      v-bind="{ ...$attrs, label, readonly: true, required: true }"
      v-model="selectedLabel"
      @click="opened = true"
    />
    <e-menu v-bind="{ right: true, top: true }" v-model="opened">
      <div class="d-flex align-items-start justify-content-between">
        <div>
          <e-radio
            v-for="(item, i) in options"
            :key="i"
            v-bind="{
              value: item.value,
              label: item.label
            }"
            v-model="selected"
            style="line-height: 1.2"
          />
        </div>
      </div>
    </e-menu>
    <div class="d-flex align-items-center justify-content-between mt-4">
      <div :class="$bem('label')">Кількість кімнат <sub :class="$bem('required')">*</sub></div>
      <e-counter
        v-if="type !== 'commerce'"
        :class="[$bem('rooms-counter'), 'align-items-center']"
        v-model="localRooms"
        v-bind="{ required: true }"
      >
      </e-counter>
    </div>
  </div>
</template>

<script>
import { HOUSE_TYPES, APT_TYPE_CONSTANTS_DOUBLE, OBJECT_TYPES } from '@/helpers/constants';
import eMenu from '@/elements/e-menu';
import { objectToArray } from '@/utils/object-to-array';
import eRadio from '@/elements/inputs/e-radio';
import eInput from '@/elements/inputs/e-input';
import component from '@/mixins/component';
import eCounter from '../../../elements/e-counter'

export default {
  name: 'type-apartments',
  components: { eCounter, eInput, eRadio, eMenu },
  mixins: [component],
  data: () => ({
    opened: false
  }),
  computed: {
    localRooms: {
      get() {
        return this.rooms
      },
      set(val) {
        this.$emit('roomsChange', val)
      }
    },
    selected: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    options() {
      switch(this.type) {
        case 'house': return objectToArray(HOUSE_TYPES);
        case 'flat': return objectToArray(APT_TYPE_CONSTANTS_DOUBLE);
        case 'commerce': return objectToArray(OBJECT_TYPES);
        default: []
      }
    },
    selectedLabel({ value }) {
       switch(this.type) {
        case 'house': return value ? HOUSE_TYPES[this.value] : '';
        case 'flat': return this.value ? APT_TYPE_CONSTANTS_DOUBLE[this.value] : '';
        case 'commerce': return  this.value ? OBJECT_TYPES[this.value] : '';
        default: []
      }
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'house'
    },
    rooms: {
      type: Number,
      default: 0
    }
  }
};
</script>
