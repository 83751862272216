<template>
  <div class="col-auto stock-container mt-2">
    <div class="cabinet-pay-wrapper m-auto">
      <h2 class="filter-main-title" id="payments">Акції</h2>
      <span
        class="stock-container__back mt-1 mb-1"
        @click="$router.push('/profile')"
        >Перейти до профілю</span>
      <div class="filter-marg"></div>
      <div class="d-flex flex-column flex-sm-row justify-content-start">
        <div
          class="cabinet-pay-block cabinet-pay-block--stock p-3 d-flex flex-column"
        >
          <div class="cabinet-pay-block--header">
            <p>
              <b>
                🤑 Такого ви ще не бачили! Знаєте про реальний об’єкт, якого немає у нас у базі? Ми готові платити за нього!🥳
              </b>
            </p>

            <p>
              <b class="mt-2">
                Платимо за модерацію 🤑 Знаєш, що об’єкт неактуальний? Тисніть на червону кнопку, після підтвердження неактуальності отримай на рахунок 2 Easycoin.
              </b>
            </p>
          </div>
          <div class="mt-5">
            <p>
              <b style="color: #27a39f">Скільки сплачуємо за об'єкт?</b> <br />
              За додану квартиру +45 EasyCoin<br />
              За доданий будинок +35 EasyCoin<br />
              За доданий об'єкт оренди окрім комерції +20 EasyCoin<br>
              За доданий об'єкт продаж/оренда комерційної нерухомості +30 EasyCoin.<br>
              За об’єкт пригороду +10 EasyCoin<br>
              <span style="color: #27a39f">
                “нарахування відбувається за умови самостійного додавання об’єкту через сайт”<br>
                За додавання об’єкту через підтримку +10 EasyCoin не залежно від типу об’єкту
              </span>
            </p>
            <p>
              <b style="color: #27a39f">Що таке Easycoin?</b> <br />
              Ізікоїн це наша внутрішня валюта, за яку можна придбати доступ до бази! 1 коїн = 1грн
            </p>
            <p>
              <b style="color: #27a39f">За які об'єкти нараховується оплата?</b>
              <br />
              За унікальні, яких немає у базі\архіві! <br />
              Квартиру потрібно додати через форму на сайті натиснувши (+ об'єкт) або надіслати
              всю інформацію у підтримку сайту! Навіть якщо це об'єкт вашого колеги, після
              підтвердження вона буде додана та нараховані бонуси!
            </p>
            <p>
              <b style="color: #27a39f"
                >За які об'єкти не нараховується оплата?</b>
              <br />
              Об'єкти "на трьох", "Заманухи", "дублі"<br />
              Об'єкти, які будуть продаватися "колись"<br />
              Об'єкти, у яких проблемні документи, заставне майно, арешт тощо.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StockPage'
};
</script>

<style scoped>
.cabinet-pay-block--stock {
  max-width: 600px;
  padding: 0;
  font-size: 14px;
  width: 100%;
}
.cabinet-pay-block--header {
  font-size: 16px;
}
.stock-container .cabinet-pay-wrapper {
  padding: 0;
}

.stock-container__back {
  text-align: left;
  text-decoration: underline;
}
</style>
