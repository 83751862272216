<template>
  <div class="card-user">
    <div class="client-row" @click="handleSearhObjects">
      <div class="form-required-container">
        <!-- title -->
        <div class="row-client row-client__name">
          {{ item.name }}
        </div>
        <div class="row-client">
          <span>
            <b>Кількість кімнат: </b>
          </span>
          <span>
            <span v-for="(room, i) in item.rooms" :key="i">
              {{ room }},
            </span>
          </span>
        </div>
        <div class="row-client row-client__phone" v-if="item.phone">
          <svg class="icon phone-icon">
            <use xlink:href="~@/assets/icons/phone.svg#phone"></use>
          </svg>
          <a :href="`tel:${item.phone}`" class="pointer-link">
            {{ item.client_phone }}
          </a>
          <a
            :href="`viber://chat?number=%2B38${item.phone}`"
            target="_blank"
          >
            <svg class="icon viber-logo">
              <use xlink:href="@/assets/icons/viber.svg#viber"></use>
            </svg>
          </a>
        </div>
        <div class="row-client">
          <span>
            <b>Район:</b>
          </span>
          <span>
            {{ getMicrodistricts }}
          </span>
        </div>
        <div class="row-client">
          <span>
            <b>Бюджет:</b>
          </span>
          <span>
            {{ item.priceTo }}
          </span>
        </div>
        <p class="card-user__comment">{{ item.comment }}</p>
      </div>
    </div>

    <div class="form-row justify-content-between">
      <div
        class="icon-action"
        @click="handleEditClick(item)"
      >
        <icon name="edit_icon" class="nav-search-icon" />
        Редагувати
      </div>

      <div class="icon-action" @click="deleteClient(item.id)">
        <icon name="trash_icon" class="nav-search-icon" />
        Видалити
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'ItemClient',
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      microdistrict: (state) => state.addresses.microdistricts
    }),
    getMicrodistricts() {
      return this.microdistrict
        .filter((i) => {
          return this.item.microdistrict.includes(i.id);
        })
        .map((i) => i.name)
        .join(', ');
    }
  },
  methods: {
    ...mapActions(['getObjects']),
    ...mapMutations([
      'SET_ACTIVE_CLIENT',
      'DELETE_GROUP_CLIENT_FILTERS',
      'SET_CLIENT_FILTERS'
    ]),
    async deleteClient(id) {
      const response = await api.deleteClient(id);

      if (response.status === 204) {
        this.DELETE_GROUP_CLIENT_FILTERS(id);
      }
    },
    handleSearhObjects() {
      if (window.innerWidth > 991) {
        document.getElementsByClassName(
          'simplebar-content-wrapper'
        )[1].scrollLeft = 0; // for desktop
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // for mobile
      }

      const clientFilter = this.$store.state.clients.groupsClientFilters.find(
        (i) => i.id === this.item.id
      );
      this.SET_CLIENT_FILTERS(clientFilter);
      this.$store.commit('SET_PAGE', 1);
      this.getObjects({isClient: true, type: this.item.type})
    },
    handleEditClick(item) {
      this.SET_ACTIVE_CLIENT(null);
      this.SET_ACTIVE_CLIENT(item);
      this.$router.push('/client');
    }
  }
};
</script>

<style lang="sass">
.icon-action
  display: flex
  align-items: center
  padding: 0 5px
  svg
    margin-right: 3px
.row-client
  align-items: center
  &__phone
    display: flex
    align-items: center
    .viber-logo
      width: 25px
      height: 25px
    .phone-icon
      width: 20px
      height: 20px
    .pointer-link
      display: block
      margin: 0 5px
</style>
