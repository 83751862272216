<template>
  <div :class="[$bem(), 'mt-3']">
    <e-checkbox
      v-model='model'
      label='Продавець оплачує комісію'
    />
  </div>
</template>

<script>
import component from '../../../../../../mixins/component';
import eCheckbox from '../../../../../../elements/inputs/e-checkbox';

export default {
  name: 'status-advertising',
  components: { eCheckbox },
  mixins: [component],

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>
