<template>
  <div>
    <div class="col-filter" v-if="windowWidth > 991">
      <ClientFilter @scrollVertical="handleVerticalScroll" />
    </div>

    <div class="col-items base-items">
      <div>
        <div style="margin-top: 35px;" v-if="windowWidth < 991"/>
        <simplebar
          data-simplebar-auto-hide="false"
          class="simplebar-horizontal-only"
          id="catalog-scroll"
        >
          <div
            v-if="windowWidth > 991"
            class="catalog-item-container d-flex flex-column flex-lg-row"
            id="container-mobile"
          >
            <div v-for="apt in objects" :key="apt.id">
              <b-tooltip
                v-if="apt.is_archive === '1'"
                :target="`tooltip-target-${apt.id}`"
                triggers="hover"
              >
                Объект продан, через 3 дня после подтверждения будет перенесён в
                архив!
              </b-tooltip>
              <!-- show object -->
              <object-popup v-model="objectsPopup" :apartments="apartments">
                <template #activator="{open}">
                  <catalog-card
                    v-if="isShowObjects"
                    :id="`tooltip-target-${apt.id}`"
                    @edit-object="open"
                    :apartment="apt"
                    :edit="true"
                  />
                </template>
              </object-popup>
            </div>
          </div>
          <div v-else ref="container">
            <div>
              <catalog-card
                v-for="apt in objects"
                :apartment="apt"
                :key="apt.id"
              />
            </div>
            <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler" v-if="objects.length > 0">
              <div slot="spinner" class="loader-infinity"></div>
            </infinite-loading>
          </div>
        </simplebar>
      </div>
      <div class="item-search-container" v-if="windowWidth > 991">
        <span class="item-search-label">Знайдено</span>
        <div class="navbar-count">
          {{ this.objects.length }}/{{ this.count }}
        </div>
      </div>
      <mobile-header
        :objectsCount="this.objects.length"
        :totalCount="this.count"
        v-if="windowWidth < 991"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Simplebar from 'simplebar-vue';
import CatalogCard from '@/components/app/CatalogCard';
import InfiniteLoading from 'vue-infinite-loading';
import ObjectPopup from '@/components/popups/add-object-popup/v2/object-popup';
import { NOTIFICATIONS } from '../helpers/constants';
import MobileHeader from '../components/app/mobile-header/mobile-header.vue';

export default {
  name: 'MiddleClientComponent',
  components: {
    Simplebar,
    CatalogCard,
    InfiniteLoading,
    ObjectPopup,
    ClientFilter: () => import('@/components/clients/ClientFilter'),
    MobileHeader
},
  data() {
    return {
      windowWidth: 0,
      isAllowStaticShowObjects: false,
      objectsPopup: false,
    };
  },
  created() {
    this.handleGetMicroDistrict()
    this.handleGetStreet()
    this.handleGetResidentialComplex()
    this.handleGetCities()
  },
  methods: {
    ...mapActions(['getObjects', 'getGroupsClientFilters']),
    ...mapActions(['handleGetMicroDistrict', 'handleGetStreet', 'handleGetResidentialComplex', 'handleGetCities']),
    ...mapMutations(['SET_PAGE', 'SET_COUNT']),
    editObject(apartment) {
      this.objectsPopup = true
      this.apartments = apartment
    },
    async infiniteHandler($state) {
      if (this.objects.length > 0 && this.$store.state.main.page) {
        await this.getObjects({ isClient: true });
        $state.loaded();
      }
    },
    handleVerticalScroll(page) {
      this.getGroupsClientFilters(page)
    },
    handleScroll() {
      const scrollContent = document.getElementsByClassName(
        'catalog-item-container'
      );
      const sumValue = Math.ceil(
        this.scrollBar[0].offsetWidth + this.scrollBar[0].scrollLeft
      );

      if (this.windowWidth > 991 && sumValue >= scrollContent[0].offsetWidth) {
        if (!this.page) {
          // isset next page
          this.$notify({
            group: 'app',
            type: 'success',
            text: NOTIFICATIONS.viewedAllObjects,
            ignoreDuplicates: true
          });
          return;
        }

        this.isAllowStaticShowObjects = true;
        this.getObjects({isClient: true})
      }
    }
  },
  computed: {
    ...mapState({
      objects: (state) => state.main.objects,
      page: (state) => state.main.page,
      count: (state) => state.main.count,
      loaderObject: (state) => state.main.loaderObject
    }),
    isShowObjects() {
      return !(this.loaderObject && !this.isAllowStaticShowObjects);
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.isAllowStaticShowObjects = false;
    this.getGroupsClientFilters();

    this.SET_PAGE(1);
    this.SET_COUNT(0);
    this.$store.commit('SET_OBJECTS', []);

    // add event listen scroll | simplebar
    if (this.windowWidth > 991) {
      this.scrollBar = document.querySelectorAll(
        '.base-items  .simplebar-content-wrapper'
      );
      this.scrollBar[0].addEventListener('scroll', this.handleScroll);
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    // remove event listen scroll | simplebar
    if (this.windowWidth > 991) {
      window.removeEventListener('resize', this.handleResize);
      this.scrollBar[0].removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('scroll', this.handleScroll);
    }
  }
};
</script>

<style>
.col-filter {
  font-size: 16px;
}
.tooltip {
  opacity: 1;
}
</style>
