<template>
  <div>
    <div class="col-filter">
      <div class="row">
        <div
          class="col res-modal-wrapp"
          :class="{ show: this.$parent.showFiltersModal }"
          ref="resFiltersModal"
        >
          <FilterObjects />
        </div>
      </div>
    </div>

    <div class="col-items" v-cloak>
      <object-popup v-model="objectsPopup" :apartments="apartments">
        <template #activator="{open}">
          <div style="margin-top: 35px;" v-if="windowWidth < 991"/>
          <simplebar
            data-simplebar-auto-hide="false"
            class="simplebar-horizontal-only"
            id="catalog-scroll"
          >
            <div
              class="catalog-item-container d-flex flex-column flex-lg-row"
              v-if="windowWidth > 991"
            >
              <catalog-card
                v-for="apt in objects.results"
                :apartment="apt"
                :windowWidth="windowWidth"
                @edit-object="open"
                :edit="true"
                :key="apt.id"
              />
            </div>
            <div ref="container" class="container-test" v-else>
              <br>
              <catalog-card
                v-for="apt in objects.results"
                :windowWidth="windowWidth"
                @edit-object="open"
                :apartment="apt"
                :key="apt.id"
                :edit="true"
              />
              <infinite-loading
                v-if='shouldShownInfinityHandler'
                ref="infiniteLoading"
                @infinite="infiniteHandler">
                <div slot="spinner" class="loader-infinity"></div>
              </infinite-loading>
            </div>
          </simplebar>
        </template>
      </object-popup>
      <div class="item-search-container">
        <div class="navbar-count">{{ objects.results.length }}/{{ resultsCount }}</div>
      </div>
      <mobile-header
        :objectsCount="objects.results.length"
        :totalCount="resultsCount"
        v-if="windowWidth < 991"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Simplebar from 'simplebar-vue';
import CatalogCard from '../components/app/CatalogCard.vue';
import InfiniteLoading from 'vue-infinite-loading';
import ObjectPopup from '../components/popups/add-object-popup/v2/object-popup';
import LabelsMixin from '@/mixins/LabelsMixin';
import FilterObjects from '@/components/app/filters/filter-my-objects/FilterObjects';
import { NOTIFICATIONS } from '../helpers/constants';
import MobileHeader from '../components/app/mobile-header/mobile-header.vue';

export default {
  name: 'ObjectsPage',
  components: {
    ObjectPopup,
    Simplebar,
    CatalogCard,
    InfiniteLoading,
    FilterObjects,
    MobileHeader
},
  mixins: [LabelsMixin],
  data() {
    return {
      windowWidth: null,
      objectsPopup: false,
      apartments: undefined,
      buildingType: 'flat'
    };
  },
  computed: {
    ...mapState({
      objects: (state) => state.my_objects.objects,
      loader: (state) => state.main.loaderObject
    }),
    shouldShownInfinityHandler({objects}) {
      return objects.results.length !== objects.count
    },
    resultsCount() {
      return this.objects.count || 0;
    }
  },
  created() {
    this.handleGetMicroDistrict()
    this.handleGetStreet()
    this.handleGetResidentialComplex()
    this.handleGetCities()
  },
  methods: {
    ...mapActions(['handleGetObjects', 'handleGetMicroDistrict', 'handleGetStreet', 'handleGetResidentialComplex', 'handleGetCities']),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    editObject(apartment) {
      this.objectsPopup = true
      this.apartments = apartment
    },
    async infiniteHandler($state) {
      this.loading = true;
      const count = this.objects.next;
      if (!this.loader) {
        await this.handleGetObjects( {page: count});
      }

      setTimeout(() => {
        $state.loaded();
        this.loading = false;
      }, 1000);
    },
    handleScroll() {
      const scrollContent = document.getElementsByClassName(
        'catalog-item-container'
      );
      const sumValue = Math.ceil(
        this.scrollBar[0].offsetWidth + this.scrollBar[0].scrollLeft
      );

      if (this.windowWidth > 991 && sumValue >= scrollContent[0].offsetWidth) {
      const count = this.objects.next;
        if (!count) {
          // isset next page
          this.$notify({
            group: 'app',
            type: 'success',
            text: NOTIFICATIONS.viewedAllObjects,
            ignoreDuplicates: true
          });
          return;
        }

        this.isAllowStaticShowObjects = true;
        this.handleGetObjects({page: count});
      }
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    if (this.windowWidth > 991) {
      await this.handleGetObjects();
      this.scrollBar = document.getElementsByClassName(
        'simplebar-content-wrapper'
      );
      this.scrollBar[0].addEventListener('scroll', this.handleScroll);
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    if (this.windowWidth > 991) {
      window.removeEventListener('resize', this.handleResize);
      this.scrollBar[0].removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('scroll', this.handleScroll);
    }
  }
};
</script>

<style scoped></style>
