<template>
  <div :class="[$bem(), 'd-flex']">
    <sub v-show="required" :class="$bem('required')">*</sub>
    <div class="mr-2" v-for="(item, i) in options" :key="i">
      <e-radio
        v-model="selected"
        v-bind="{ value: item.value, label: item.label }"
        style="line-height: 1.2"
      />
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import eRadio from '../../elements/inputs/e-radio';
import { BUSINESS_TYPES } from '@/helpers/constants';
import { objectToArray } from '@/utils/object-to-array';

export default {
  name: 'business-type',
  components: { eRadio },
  mixins: [component],
  computed: {
    options() {
      return objectToArray(BUSINESS_TYPES);
    },
    selected: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  props: {
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: String,
      default: ''
    }
  }
};
</script>
