<template>
<div :class="[$bem(), 'mr-auto', 'ml-auto']">
  <e-text is="h3" :class='$bem("title")'>{{ isEdit ? 'Редагувати' : 'Додати' }} об`єкт</e-text>

  <div :class='$bem("form-container")'>
    <context-wrapper
      :class="['row', 'ml-auto', 'mr-auto', 'mb-4']"
      title="Загрузка фото"
    >
      <div class="col-12">
        <image-uploader
          v-model="newImages"
          :images="form.images"
          @update-sorting="newSorting = $event"
          :apartment-id="form.id"
        />
      </div>
    </context-wrapper>

    <context-wrapper
      :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
      title="Адреса"
    >
      <location-select
        v-model="modelCity"
        v-bind="{ title: 'Локація', required: true, error: errors.city }"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />
      <districts-select
        v-model="form.district"
        v-bind="{ title: 'Район', required: true, error: errors.district }"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />
      <div :class="[calculateClassForBigComponent, 'mt-4']">
        <microdistricts-select
          v-show="form.city && form.city.is_city"
          v-model="form.microdistrict"
          v-bind="{
            title: 'Мікрорайон',
            required: true,
            error: errors.microdistrict
          }"
        />
      </div>
      <streets-select
        v-model="form.street"
        v-bind="{
          title: 'Вулиця',
          required: true,
          error: errors.street
        }"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />
      <numeric-type-field
        v-model="form.house_number"
        v-bind="{ label: 'Номер будинку', error: errors.house_number }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />

      <house-letters
        v-model="form.house_letter"
        :class="[calculateClassForSmallComponent, 'mt-4']"/>

      <e-input
        v-if="isApartments"
        v-model="form.house_hull"
        label="Корпус"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />

      <residential-complex-select
        v-if="!isCommerce"
        v-model="form.residential_complex"
        title="ЖК"
        :is-building="false"
        :error="errors.residential_complex"
        :required="form.apt_type === 'new_building'"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />

      <residential-complex-select
        v-else-if="isCommerce"
        v-model="form.building"
        title="ЖК, ТЦ, ТРЦ, БЦ"
        :is-building="true"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />
    </context-wrapper>

    <context-wrapper
      :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
      title="Параметри 1"
    >
      <div class="row mt-4">
        <type-real-estate
          v-model="form.type"
          class="col-4"
          v-bind="{ label: 'Тип', required: true, error: errors.type }"
        />
        <business-type v-model="form.business_type" class="col-4"/>
        <e-checkbox
          v-show="form.type === 'flat'"
          v-model="form.is_angled"
          label="Кутова"
          :class="[calculateClassForSmallComponent, 'angeled']"
        />
      </div>
    </context-wrapper>

    <context-wrapper
      :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
      title="Параметри 2"
    >

    </context-wrapper>
  </div>

  <div class="row mt-4">
    <div class="flex-center">
      <e-btn
        :disabled="disabledButton"
        :class="['btn-large', $bem('btn-submit'), disabledButton]"
        @click="submit(form)"
      >
        {{ isEdit ? 'Редагувати' : 'Додати' }}
        </e-btn>
    </div>
  </div>
</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import ContextWrapper from '@/components/context-wrapper';


import { mapActions, mapGetters } from 'vuex';
// import ePopup from '../../../../elements/popups/e-popup';
import component from '@/mixins/component';
import eInput from '@/elements/inputs/e-input';
import eCheckbox from '@/elements/inputs/e-checkbox';
// import eTextarea from '../../../../elements/e-textarea';
// import eBtn from '../../../../elements/buttons/e-btn';
import imageUploader from '@/components/popups/add-object-popup/v2/components/image-uploader/image-uploader';
import typeRealEstate from '@/components/estate/type-real-estate';
import BusinessType from '@/components/business-type';
import locationSelect from '@/components/address/location';
import districtsSelect from '@/components/address/districts';
import microdistrictsSelect from '@/components/address/microdistricts';
import streetsSelect from '@/components/address/streets';
import residentialComplexSelect from '@/components/address/residential-complex';
// import typeApartments from '../../../estate/type-apartments';
// import typeMaterial from '../../../estate/type-material';
// import labels from './components/labels';
// import additions from './components/additions';
// import selectConfig from '../../../select-config';
// import { apartmentsValidate, defaultData, prepareValidationFields, getMessage } from './helpers';
// import statusObject from './components/status-object';
// import cashlessPayments from './components/cashless-payments';
// import statusAdvertising from './components/status-advertising';
// import statusPaysCommission from './components/status-pays-commission';
// import statusAnimals from './components/status-animals';
// import statusChildren from './components/status-children';
// import statusForeigners from './components/status-foreigners';
import HouseLetters from '@/components/address/house-letters';
// import HouseNumber from '../../../address/house-number';
import NumericTypeField from '@/elements/inputs/numeric-input/numeric-input';
// import CommunicationField from './components/communication-field/communication-field';
// import LabelsMixin from '@/mixins/LabelsMixin';


const initialForm = {
  apt_type: '',
  business_type: 'sell',
  city: null,
  district: [],
  microdistrict: [],
  street: [],
  material_type: '',
  labels: [],
  options: [],
  house_letter: '',
  type: '',
  price: null,
  floors: null,
  floor: null,
  user_label: [],
  house_number: "",
  residential_complex: [],
  building: [],
  square_common: null,
  square_living: null,
  square_kitchen: null,
  text: '',
  images: [],
  house_hull: '',
  repair: '',
  square_area: null,
  status: 'created',
  source: 'rieltor',
  rooms: 0,
  is_angled: false,
  water: '',
  sewers: '',
  gas: '',
  heating: ''
};

export default {
  name: 'manageObjectFormPage',
  mixins: [component],
  components: {
    // CommunicationField,
    NumericTypeField,
    // HouseNumber,
    HouseLetters,
    // statusObject,
    // statusAdvertising,
    // statusPaysCommission,
    // eBtn,
    // selectConfig,
    // eTextarea,
    eCheckbox,
    eInput,
    // labels,
    // typeMaterial,
    // typeApartments,
    residentialComplexSelect,
    streetsSelect,
    microdistrictsSelect,
    districtsSelect,
    locationSelect,
    BusinessType,
    typeRealEstate,
    imageUploader,
    // ePopup,
    // additions,
    // statusAnimals,
    // statusChildren,
    // statusForeigners,
    // cashlessPayments,

    ContextWrapper
  },

  data() {
    return {
      form: {
        ...initialForm
      },
      newImages: [],
      newSorting: [],

      activeObject: {},
      errors: {
        city: '',
        district: '',
        microdistrict: '',
        street: '',
        square_common: '',
        repair: '',
        apt_type: '',
        square_area: '',
        floors: '',
        floor: '',
        price: '',
        type: '',
        rooms: ''
      },
    }
  },

  computed: {
    isEdit() {
      return !isEmpty(this.activeObject)
    },
    modelCity: {
      get() {
        return this.form.city
      },
      set(val) {
        this.form.city = val;
        this.handleGetDistrictByCities(val.id);
      }
    },
    calculateClassForBigComponent: ({$mq}) => $mq !== 'xs' ? 'col-4' : 'col-6',
    calculateClassForSmallComponent: ({$mq}) => $mq !== 'xs' ? 'col-2' : 'col-4',
    isApartments: ({ form }) => form.type === 'flat',
    isCommerce: ({ form }) => form.type === 'commerce',
  },
  methods: {
    ...mapActions(['handleGetObjects', 'handleSortingImagesByApartmentsId', 'handleCreateApartment', 'handleEditObject', 'handleEditApartment', 'handleExportImages', 'handleGetDistrictByCities']),
  },

  watch: {
  },

  mounted() {
    if (this.$route.params.id) {
      // go to api IS EDIT
    }
    
    // if (this.activeClient) {
    //   this.isEditingClient = true;
    //   this.form = JSON.parse(JSON.stringify(this.activeClient));
    // } else {
    //   this.form = { ...initialForm };
    // }
  }
};
</script>

<style lang='sass' src='../assets/styles/pages/manage-object-form-page.sass'/>