<template>
  <e-input
    v-model="model"
    label="Номер будинку"
    :class="['mt-4']"
  />
</template>

<script>
import component from '../../../mixins/component';
import EInput from '../../../elements/inputs/e-input/e-input';
export default {
  name: 'house-number',
  components: { EInput },
  mixins: [component],
  computed: {
    model: {
      get() {
        return this.value ? this.value.replace(/[^\d.]/g, '') :  this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  }
};
</script>
