import api from '@/api';
import { createClientEntity } from '../../factories/createClientEntity';

const prepareParamsForSending = (data) => {
  // This is a temporary solution. These checks must occur at the presenter level
  const getValForNumber = (val) =>  {
    if( Math.trunc(val) === 0 || val === "" ) {
      return null;
    }

    return +val
  }

  const prepareData = {};
  prepareData.id = data.id;
  prepareData.client_name = data.name,
  prepareData.client_phone = data.phone,
  prepareData.city = data.city?.id;
  prepareData.district = data.district.map(item => item.id);
  prepareData.building_id = data.building?.id;
  prepareData.microdistrict = data.microdistrict.map(item => item.id);
  prepareData.residential_complex = data.residentialComplex?.map(item => item.id);
  prepareData.street = data.street.map(item => item.id);
  prepareData.price_to = getValForNumber(data.priceTo);
  prepareData.apt_type = data.aptType?.map(item => item.value);
  prepareData.type = data.type;
  prepareData.floor_from = getValForNumber(data.floorFrom);
  prepareData.floor_to =  getValForNumber(data.floorTo);
  prepareData.floors_from =  getValForNumber(data.floorsFrom);
  prepareData.floors_to =  getValForNumber(data.floorsTo);
  prepareData.square_area_from =  getValForNumber(data.squareAreaFrom);
  prepareData.square_area_to =  getValForNumber(data.squareAreaTo);
  prepareData.house_number =  getValForNumber(data.houseNumber);
  prepareData.business_type = data.businessType;
  prepareData.price_from =  getValForNumber(data.priceFrom);
  prepareData.price_to =  getValForNumber(data.priceTo);
  prepareData.rooms = data.rooms;
  prepareData.comment = data.comment;
  prepareData.square_common_from = getValForNumber(data.squareCommonFrom);
  prepareData.square_common_to = getValForNumber(data.squareCommonTo);
  prepareData.repair = data.repair.map(item => item.value);

  prepareData.exclude_unmoderated = data.exclude_unmoderated
  prepareData.is_cashless_payment_available = data.is_cashless_payment_available
  prepareData.is_children_allowed = data.is_children_allowed
  prepareData.is_foreigners_allowed = data.is_foreigners_allowed
  prepareData.is_pets_allowed = data.is_pets_allowed
  prepareData.is_seller_pays_the_commission = data.is_seller_pays_the_commission
  prepareData.is_used_for_advertising = data.is_used_for_advertising

  if (!data.type === 'house') {
    delete prepareData.square_area;
  }

  return prepareData;
};

export const state = {
  groupsClientFilters: [],
  activeClient: null
};

export const mutations = {
  SET_GROUPS_CLIENT_FILTERS(state, payload) {
    state.groupsClientFilters = payload;
  },
  SET_ACTIVE_CLIENT(state, payload) {
    state.activeClient = payload;
  },
  DELETE_GROUP_CLIENT_FILTERS(state, payload) {
    const index = state.groupsClientFilters.findIndex((i) => i.id === payload);
    state.groupsClientFilters.splice(index, 1);
  },
  UPDATE_GROUP_CLIENT_FILTERS(state, payload) {
    const index = state.groupsClientFilters.findIndex((i) => {
      return i.id === state.activeClient.id;
    });
    state.groupsClientFilters.splice(index, 1, payload);
  }
};

export const actions = {
  async handleCreateClient({ commit }, data) {
    const prepareData = prepareParamsForSending(data);
    try {
      return api.createClient(prepareData);

    } catch (e) {
      console.log('Failed create client error:', e);
    }
  },

  async handleEditClient(context, data) {
    const prepareData = prepareParamsForSending(data);

    try {
      return api.editClient(prepareData.id, prepareData)
    } catch (e) {
      console.log('Failed edit client error:', e);
    }
  },
  async getGroupsClientFilters({ commit }, limit = 100) {
    const response = await api.getClients(limit);

    if (response.status === 200) {
      commit('SET_GROUPS_CLIENT_FILTERS', response.data.results.map(data => createClientEntity(data)));
    }
  }
};
