var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[{ 'not-empty': !!_vm.value }, _vm.$bem()]},[_c('input-wrapper',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"input",fn:function(ref){
var on = ref.on;
var isError = ref.isError;
return [_c('input',_vm._g(_vm._b({directives:[{name:"input-autofocus",rawName:"v-input-autofocus",value:(_vm.autofocus),expression:"autofocus"}],class:[_vm.$bem('input')]},'input',Object.assign({}, {name: _vm.name,
          value: _vm.value,
          type: _vm.type,
          required: _vm.required,
          disabled: _vm.disabled,
          size: _vm.size,
          readonly: _vm.readonly,
          placeholder: _vm.placeholder},
          _vm.nativeProps),false),Object.assign({}, _vm.$listeners,
          on,
          {click: function () { return _vm.$emit('click'); },
          input: function (e) { return _vm.$emit('input', e.target.value); }}))),_c('label',_vm._b({directives:[{name:"show",rawName:"v-show",value:(!!_vm.label),expression:"!!label"}],class:[_vm.$bem('label')]},'label',{ disabled: _vm.disabled },false),[_vm._v(" "+_vm._s(_vm.label)+" "),_vm._t("label",null,null,{ label: _vm.label, isError: isError }),_c('sub',{directives:[{name:"show",rawName:"v-show",value:(_vm.required),expression:"required"}],class:_vm.$bem('required')},[_vm._v("*")])],2)]}}],null,true)},'input-wrapper',_vm.$attrs,false),Object.assign({}, _vm.$listeners,  {click: function () { return _vm.$emit('click'); }})))],1)}
var staticRenderFns = []

export { render, staticRenderFns }