<template>
  <div>
    <div class="add-item-menu" :class="{ show: isActive }">
      <ul>
        <li>
          <a href="#"
             class="pl-1"
             @click="handleObjectPopupClick">
            <!-- <icon name="build_add_icon" class="add-item-icon" /> -->
            <img :src="house" alt="house" class="mr-1">
            Додати об'єкт
          </a>
        </li>
        <li>
          <a
            href="#"
            class="pl-1"
            @click="handleNewClientClick"
          >
            <!-- <icon name="mail_add_icon" class="add-item-icon" /> -->
            <img :src="clients" alt="clients" class="mr-1">
            Додати пошук
          </a>
        </li>
        <li>
          <a href="#" pl- v-b-modal.add-feedback-popup>
            <icon name="why" class="add-item-icon" />Підтримка
          </a>
        </li>
        <li>
          <a href="#" v-b-modal.referral-popup style="color: #27a39f">
            <icon name="mail_add_icon" class="add-item-icon" />
            <b>Запросити колегу до бази</b>
          </a>
        </li>
      </ul>
    </div>
    <a
      href="#"
      v-if="!this.$route.meta.menu"
      class="btn-plus add-item-button"
      :class="{ active: isActive }"
      @click.prevent="toggleMenu"
    >
      <icon name="plus_icon" class="btn-plus-icon" />
    </a>
    <!--    <add-object-popup-v2 />-->
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
// import AddObjectPopupV2 from '../popups/add-object-popup/v2';
import house from '@/assets/icons/house.svg';
import clients from '@/assets/icons/clients.svg';

export default {
  name: 'AddItemButton',
  // components: { AddObjectPopupV2 },
  data() {
    return {
      isActive: false,
      windowWidth: 0,
      house,
      clients
    };
  },
  methods: {
    ...mapMutations(['SET_ACTIVE_CLIENT']),
    toggleMenu() {
      this.isActive = !this.isActive;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    handleObjectPopupClick() {
      this.$emit('open', 'object-popup');
      this.toggleMenu()
    },

    handleNewClientClick() {
      this.SET_ACTIVE_CLIENT(null);
      this.$router.push('/client');
      this.toggleMenu()
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
};
</script>

<style scoped lang="sass">
.disabled-menu
  position: relative
  z-index: 1
  filter: blur(0.75px)
  &:after
    content: ''
    position: absolute
    display: block
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 2
    background-color: rgba(255, 255, 255, 0.2)
    filter: blur(2px)
.add-item-menu
  border-radius: 10px
  position: fixed
  z-index: 2
  right: 0
  bottom: 86px
  width: 200px
  background-color: $menu_color
  transition: 0.2s
  transform: translateX(200px)
  padding: 10px
  box-shadow: 5px 7px 20px rgba(0, 0, 0, 0.6)

  ul
    margin: 0
    padding: 0
    list-style: none

    li
      width: 100%
      border-bottom: 1px solid $body_color
      display: block
      &:hover
        a
          color: #fff
          background: rgba(255, 255, 255, 0.1)

      &:last-child
        border-bottom: none

      a
        display: block
        padding: 10px

      // &:hover
      //   background-color: white

      //   a
      //     .add-item-icon
      //       fill: $active_navbar_color

  &.show
    transform: translateX(0)

.add-item-icon
  width: 15px
  height: 15px
  margin-right: 10px

.add-item-button
  svg
    transition: transform .2s ease
  &.active
    svg
      transform: rotate(45deg)

.viber-item-button
  bottom: 90px
  @media (max-width: 991px)
    bottom: 125px
</style>
