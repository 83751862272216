<template>
  <div :class="$bem()">
    <div :class="$bem('title')">{{ this.$route.meta.title }}</div>
    <div>
      <div v-if="!loaderObject">
        <div>Відкрито об'єктів:</div>
        <div :class="$bem('numbers')">
          <div :class="$bem('large')">{{ objectsCount }}</div>
          <div>|</div>
          <div :class="$bem('small')">{{ totalCount }}</div>
        </div>
      </div>

      <div :class="$bem('preloader')" v-if="loaderObject">
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
        <div class="dot dot3"></div>
        <div class="dot dot4"></div>
      </div>
    </div>
    <div>
      <notification-tooltip @open-modal="openModal($event)" />
      <notification-popup :notification="notification" v-model="model" />
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import notificationPopup from '@/components/app/navbar/components/notification-popup';
import notificationTooltip from '@/components/app/navbar/components/notification-tooltip';
import { mapState } from 'vuex';

export default {
  name: 'mobile-header',
  components: { notificationPopup, notificationTooltip },
  mixins: [component],
  props: {
    objectsCount: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      model: false,
      show: false,
      notification: undefined
    };
  },
  methods: {
    openModal(notification) {
      this.model = true;
      this.notification = notification;
    }
  },
  computed: {
    ...mapState({
      loaderObject: (state) => state.main.loaderObject
    })
  }
};
</script>
<style lang="sass">
.mobile-header
  padding: 10px 16px
  max-height: 46px
  position: fixed
  background: #4E6F90
  display: flex
  width: 100%
  z-index: 9
  justify-content: space-between
  align-items: center
  &__numbers
    display: flex
    justify-content: center
    align-items: flex-end
    gap: 5px
  &__large
    font-size: 15px
  &__small
    font-size: 12px
  &__title
    font-size: 18px
  &__preloader
    font-size: 18px
    text-align: center
    margin: auto
    height: 10px
    display: block
    width: 50px
    background: transparent
    position: relative
</style>
