<template>
  <div :class="$bem()">
    <upload-images
      :class="[$bem('form'), 'p-3']"
      :extensions="extensions"
      :accept="accept"
      :multiple="multiple"
      :directory="directory"
      :create-directory="createDirectory"
      :size="size || 0"
      :headers="headers"
      :data="data"
      :drop="drop"
      :drop-directory="dropDirectory"
      :add-index="addIndex"
      v-model="files"
      @input-filter="inputFilter"
      @input-file="inputFile"
      ref="upload"
    >
      <h5>Виберіть фото</h5>
    </upload-images>
    <draggable
      v-model="files"
      :class="[$bem('container'), 'p-3 d-flex mt-3 justify-content-start']"
      draggable=".item"
      @start="dragging = true"
      @end="dragging = false"
      @unchoose="dragFinish()"
      v-if="files.length"
    >
      <figure
        :class="[$bem('frame'), 'mr-2 item']"
        v-for="item in files"
        :key="item.id"
      >
        <img
          :class="$bem('img')"
          :src="item.thumb || item.blob"
        />

        <button
          v-if="item.id && isTouchEnabled"
          @touchstart="removePhoto(item.id)"
          :class="$bem('frame-button')"
        >
          <i :class="[$bem('frame-icon'), 'mdi mdi-trash-can-outline']" />
        </button>

        <button
          v-else-if="item.id && !isTouchEnabled"
          @click="removePhoto(item.id)"
          :class="$bem('frame-button')"
        >
          <i :class="[$bem('frame-icon'), 'mdi mdi-trash-can-outline']" />
        </button>
      </figure>
    </draggable>
  </div>
</template>

<script>
import UploadImages from 'vue-upload-component';
import component from '@/mixins/component';
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';

export default {
  name: 'image-uploader',
  mixins: [component],
  components: {
    UploadImages,
    draggable
  },
  data: () => ({
    extensions: 'gif,jpg,jpeg,png,webp',
    accept: 'image/png,image/gif,image/jpeg,image/webp',
    files: [],
    minSize: 1024,
    size: 1024 * 1024 * 10,
    multiple: true,
    directory: false,
    drop: true,
    dropDirectory: true,
    createDirectory: false,
    addIndex: false,
    thread: 3,
    name: 'file',
    dragging: false,
    convertedImageToBase64: []
  }),

  props: {
    images: {
      type: Array,
      default: () => []
    },
    apartmentId: {
      type: String,
      default: ''
    }
  },

  mounted() {
    this.files = this.images.map(({ id, image_realty, img_obj, primary }) => ({
      id,
      thumb: img_obj,
      image_realty,
      primary
    }));
  },

  computed: {
    isTouchEnabled() {
      return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) ||  ( navigator.msMaxTouchPoints > 0 );
    },
  },

  methods: {
    ...mapActions([
      'handleDeleteImageByApartmentsId',
      'handleCreateImagesByApartmentsId'
    ]),

    async inputFile(file) {
      if (!file) {
        return;
      }

      if (!file.size && file.srcset && file.thumb.includes('easybase.b-cdn')) {
        return;
      }
      const formData = new FormData();

      this.$emit('input', formData);
    },
    dragFinish() {
      const newSortIdsImage = this.files.map(({ id }) => id);
      this.$emit('update-sorting', newSortIdsImage);
    },
    removePhoto(id) {
      this.files = this.files.filter((a) => a.id !== id);
      this.handleDeleteImageByApartmentsId({ realtyId: this.apartmentId, id });
    },
    async inputFilter(newFile) {
      let URL = window.URL || window.webkitURL;

      if (URL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
      await this.getBase64(newFile);

      newFile.thumb = '';
      newFile.thumb = newFile.blob;
      if (this.apartmentId && this.convertedImageToBase64.length) {
        this.handleCreateImagesByApartmentsId({
          realtyId: this.apartmentId,
          images: this.convertedImageToBase64
        });
      }
    },

    getBase64({ file }) {
      if (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (this.apartmentId) {
              this.handleCreateImagesByApartmentsId({
                realtyId: this.apartmentId,
                images: [reader.result]
              });
            } else {
              this.convertedImageToBase64.push(reader.result);
            }
            this.$emit('input', this.convertedImageToBase64);
          };
          reader.onerror = (error) => reject(error);
        });
      }
    }
  }
};
</script>
