<template>
  <e-popup @close="close" :class="$bem()" v-model="opened" :footer="true">
    <template #header
      ><h5 class="modal-title text-center mb-3">{{ isEditingApartments? 'Редагувати об\'єкт': 'Додати об\'єкт' }}</h5>
    </template>
    <template #activator="binding">
      <slot name="activator" v-bind="binding" />
    </template>
    <image-uploader
      v-model="newImages"
      :images="form.images"
      @update-sorting="newSorting = $event"
      :apartment-id="form.id"/>
    <div class="row mt-4">
      <type-real-estate
        v-model="form.type"
        class="col-4"
        v-bind="{ label: 'Тип', required: true, error: errors.type }"
      />
      <business-type v-model="form.business_type" class="col-4" />
      <e-checkbox
        v-show="form.type === 'flat'"
        v-model="form.is_angled"
        label="Кутова"
        :class="[calculateClassForSmallComponent, 'angeled']" />
    </div>
    <div class="row">
      <location-select
        v-model="modelCity"
        v-bind="{ title: 'Локація', required: true, error: errors.city }"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />

      <districts-select
        v-model="form.district"
        v-bind="{ title: 'Район', required: true, error: errors.district }"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />
      <div :class="[calculateClassForBigComponent, 'mt-4']">
        <microdistricts-select
          v-show="form.city && form.city.is_city"
          v-model="form.microdistrict"
          v-bind="{
            title: 'Мікрорайон',
            required: true,
            error: errors.microdistrict
          }"
        />
      </div>
      <streets-select
        v-model="form.street"
        v-bind="{
          title: 'Вулиця',
          required: true,
          error: errors.street
        }"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />

      <numeric-type-field
        v-model="form.house_number"
        v-bind="{ label: 'Номер будинку', error: errors.house_number }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />

      <house-letters
        v-model="form.house_letter"
        :class="[calculateClassForSmallComponent, 'mt-4']"/>

      <e-input
        v-if="isApartments"
        v-model="form.house_hull"
        label="Корпус"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />

      <residential-complex-select
        v-if="!isCommerce"
        v-model="form.residential_complex"
        title="ЖК"
        :is-building="false"
        :error="errors.residential_complex"
        :required="form.apt_type === 'new_building'"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />
      <residential-complex-select
        v-else-if="isCommerce"
        v-model="form.building"
        title="ЖК, ТЦ, ТРЦ, БЦ"
        :is-building="true"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />
      <div 
          v-if="isCommerce"
          :class="['padding-0', 'col-12']"
      >
        <type-apartments
          v-model="form.apt_type"
          v-bind="{
            label: 'Тип объекта',
            type: form.type,
            required: true,
            error: errors.apt_type
          }"
          :class="[calculateClassForBigComponent, 'mt-4']"
        />
      </div>
      <type-apartments
        v-if="!isCommerce"
        v-model="form.apt_type"
        v-bind="{
          label: isHouses ? 'Тип будинку' : 'Тип квартири',
          type: form.type,
          required: true,
          error: errors.apt_type || errors.rooms
        }"
        :class="[calculateClassForBigComponent, 'mt-4']"
        :rooms="form.rooms"
        @roomsChange="form.rooms = $event"
      />
      <type-material
        v-if="isTypeMaterial"
        :class="[calculateClassForBigComponent, 'mt-4']"
        v-model="form.material_type"
      />
      <select-config
        v-model="form.repair"
        v-bind="{
          title: 'Стан',
          required: true,
          error: errors.repair,
          multiple: false
        }"
        :class="[calculateClassForBigComponent, 'mt-4']"
      />
      <labels
        v-if="!isCommerce"
        :class="[calculateClassForBigComponent, 'mt-4']"
        v-model="form.labels"
      />

      <numeric-type-field
        v-if="isSquareCommon"
        v-model="form.square_common"
        v-bind="{ label: squareCommonLabel, required: true, error: errors.square_common }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />

      <numeric-type-field
        v-if="isApartments"
        v-model="form.square_living"
        v-bind="{ label: 'Житлова', error: errors.square_living }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />

      <numeric-type-field
        v-if="isSquareArea"
        v-model="form.square_area"
        v-bind="{ label: 'Кол. соток', required: !isCommerce || form.apt_type === 'farm', error: errors.square_area }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />
      <numeric-type-field
        v-if="isRooms"
        v-model="form.rooms"
        v-bind="{ label: form.apt_type === 'office_room' ? 'Кільк. кабінетів' :  'Кільк. кімнат' , error: errors.rooms }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />
      <numeric-type-field
        v-if="!isCommerce"
        v-model="form.square_kitchen"
        v-bind="{ label: 'Кухня', error: errors.square_kitchen }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />
      <numeric-type-field
        v-if="isFloor"
        v-model="form.floor"
        v-bind="{ label: 'Поверх', required: !isCommerce, error: errors.floor }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />
      <numeric-type-field
        v-if="isFloors"
        v-model="form.floors"
        v-bind="{ label: 'Поверховість', required: !isCommerce, error: errors.floors }"
        :class="[calculateClassForSmallComponent, 'mt-4', 'col-3']"
      />
      <numeric-type-field
        v-model="form.price"
        v-bind="{ 
          label: ((form.business_type === 'sell' ? '$' : '₴')+' Вартість'), 
          required: true, 
          error: errors.price 
        }"
        :class="[calculateClassForSmallComponent, 'mt-4']"
      />
      <additions
        v-if="isAdditions"
        :class="[calculateClassForBigComponent, 'mt-4']"
        v-model="form.options"
        :apt_type="form.apt_type"
      />
    </div>
    <div v-if="isHouses" class="row">
      <communication-field
        v-model="form.heating"
        v-bind="{ title: 'Опалення' }"
        :arrayOptions="heating_object"
        :class="[calculateClassForMediumComponent, 'mt-4']" />

      <communication-field
        v-model="form.water"
        v-bind="{ title: 'Вода' }"
        :arrayOptions="water_object"
        :class="[calculateClassForMediumComponent, 'mt-4']" />
      <communication-field
        v-model="form.gas"
        v-bind="{ title: 'Газ' }"
        :arrayOptions="gas_object"
        :class="[calculateClassForMediumComponent, 'mt-4']"/>

      <communication-field
        v-model="form.sewers"
        v-bind="{ title: 'Каналізація' }"
        :arrayOptions="sewers_object"
        :class="[calculateClassForMediumComponent, 'mt-4']" />

    </div>

    <div
      v-if="isRentFlat"
      class="addition-status"
    >
      <status-animals v-model="statusAnimals"></status-animals>
      <status-children v-model="statusChildren"></status-children>
      <status-foreigners v-model="statusForeigners"></status-foreigners>
    </div>

    <e-textarea
      :class="[$bem('comments'), 'mt-4 w-100']"
      v-model="form.text"
      label="Коментарий"
    />

    <div class="mt-3">
      <p>
        Будь ласка, переконайтесь в тому, що ви додаєте об'єкт по якому маєте прямий контакт з власником.
      </p>
    </div>

    <cashless-payments v-if="form.business_type === 'sell'" v-model="cashlessPayments" />
    <status-object v-if="isShowStatusObject" v-model="statusObject" />
    <status-advertising v-model="statusAdvertising"></status-advertising>
    <status-pays-commission v-model="statusPaysCommission"></status-pays-commission>

    <template #footer>
      <div class="flex-center">
        <e-btn :disabled="disabledButtonSubmit ? 'disabled' : false"
               :class="['btn-large', $bem('btn-submit'), disabledButtonSubmit ? 'disabled' : '']" @click="submit(form)">{{
            isEditingApartments ? 'Редагувати' : 'Додати'
          }}</e-btn>
      </div>
    </template>
  </e-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ePopup from '../../../../elements/popups/e-popup';
import component from '../../../../mixins/component';
import eInput from '../../../../elements/inputs/e-input';
import eCheckbox from '../../../../elements/inputs/e-checkbox';
import eTextarea from '../../../../elements/e-textarea';
import eBtn from '../../../../elements/buttons/e-btn';
import imageUploader from './components/image-uploader';
import typeRealEstate from '../../../estate/type-real-estate';
import BusinessType from '../../../business-type';
import locationSelect from '../../../address/location';
import districtsSelect from '../../../address/districts';
import microdistrictsSelect from '../../../address/microdistricts';
import streetsSelect from '../../../address/streets';
import residentialComplexSelect from '../../../address/residential-complex';
import typeApartments from '../../../estate/type-apartments';
import typeMaterial from '../../../estate/type-material';
import labels from './components/labels';
import additions from './components/additions';
import selectConfig from '../../../select-config';
import { apartmentsValidate, defaultData, prepareValidationFields, getMessage } from './helpers';
import statusObject from './components/status-object';
import cashlessPayments from './components/cashless-payments';
import statusAdvertising from './components/status-advertising';
import statusPaysCommission from './components/status-pays-commission';
import statusAnimals from './components/status-animals';
import statusChildren from './components/status-children';
import statusForeigners from './components/status-foreigners';
import HouseLetters from '../../../address/house-letters';
import HouseNumber from '../../../address/house-number';
import NumericTypeField from '../../../../elements/inputs/numeric-input/numeric-input';
import CommunicationField from './components/communication-field/communication-field';
import LabelsMixin from '@/mixins/LabelsMixin';

export default {
  name: 'object-popup',
  components: {
    CommunicationField,
    NumericTypeField,
    HouseNumber,
    HouseLetters,
    statusObject,
    statusAdvertising,
    statusPaysCommission,
    eBtn,
    selectConfig,
    eTextarea,
    eCheckbox,
    eInput,
    labels,
    typeMaterial,
    typeApartments,
    residentialComplexSelect,
    streetsSelect,
    microdistrictsSelect,
    districtsSelect,
    locationSelect,
    BusinessType,
    typeRealEstate,
    imageUploader,
    ePopup,
    additions,
    statusAnimals,
    statusChildren,
    statusForeigners,
    cashlessPayments
  },
  mixins: [component, LabelsMixin],
  data: () => ({
    isError: false,
    loading: false,
    newImages: [],
    newSorting: [],
    roomsCounterError: '',
    form: {
      city: null,
      district: [],
      microdistrict: [],
      street: [],
      apt_type: '',
      material_type: '',
      labels: [],
      options: [],
      house_letter: '',
      type: '',
      price: null,
      floors: null,
      floor: null,
      user_label: [],
      house_number: "",
      residential_complex: [],
      building: [],
      square_common: null,
      square_living: null,
      square_kitchen: null,
      text: '',
      images: [],
      house_hull: '',
      repair: '',
      business_type: 'sell',
      square_area: null,
      status: 'created',
      source: 'rieltor',
      rooms: 0,
      is_angled: false,
      water: '',
      sewers: '',
      gas: '',
      heating: ''
    },
    errors: {
      city: '',
      district: '',
      microdistrict: '',
      street: '',
      square_common: '',
      repair: '',
      apt_type: '',
      square_area: '',
      floors: '',
      floor: '',
      price: '',
      type: '',
      rooms: ''
    },
    statusObject: false,
    statusAdvertising: false,
    statusPaysCommission: false,
    cashlessPayments: false,
    statusAnimals: false,
    statusChildren: false,
    statusForeigners: false,
    disabledButtonSubmit: false,
    opened: false
  }),
  computed: {
    ...mapGetters(['editObject']),
    isHouses: ({ form }) => form.type === 'house',
    isApartments: ({ form }) => form.type === 'flat',
    isCommerce: ({ form }) => form.type === 'commerce',
    isShowStatusObject: ({form}) => form.status !== 'approved',
    isTypeMaterial: ({form}) =>  !['autoservice', 'farm', 'trading_area'].includes(form.apt_type),
    isSquareCommon: ({form}) =>  !['farm'].includes(form.apt_type),
    isAdditions: ({form}) => {
      const apt_types = ['MAF', 'trading_area', 'farm'];
      return form.type === 'commerce' && !apt_types.includes(form.apt_type);
    },
    isFloor: ({form}) => {
      const apt_types = [
        'restaurant_or_cafe_or_bar', 
        'office_room', 'shop', 
        'beauty_object', 
        'health_object', 
        'sport_object', 
        'event_object'
      ];
      return form.type === 'flat' || (form.type === 'commerce' && apt_types.includes(form.apt_type));
    },
    isFloors: ({form}) => {
      const apt_types = [
        'recreation_center_or_hotel', 
        'industrial_premise', 
        'free_appointment_premise', 
        'MAF', 
        'autoservice', 
        'trading_area', 
        'farm', 
        'trading_area', 
        'production_object',
        'production_object'
      ];
      return form.type === 'flat' || form.type === 'house' || (form.type === 'commerce' && !apt_types.includes(form.apt_type));
    },
    isRooms: ({form}) => {
      const apt_types = [
        'office_room',
        'detached_building',
        'part_of_the_building', 
        'shop', 
        'beauty_object', 
        'health_object', 
        'sport_object', 
      ];

      return form.type === 'commerce' && apt_types.includes(form.apt_type);
    },
    isSquareArea: ({form}) => {
      const apt_types = [
        'restaurant_or_cafe_or_bar', 
        'warehouse_or_hangar', 
        'recreation_center_or_hotel', 
        'industrial_premise', 
        'free_appointment_premise', 
        'farm', 
        'event_object', 
        'production_object',
      ];
      return form.type === 'house' || form.type === 'commerce' && apt_types.includes(form.apt_type);
    },
    isRentFlat() {
      return this.form.type === 'flat' && this.form.business_type === 'rent'
    },
    
    calculateClassForBigComponent: ({$mq}) => $mq !== 'xs' ? 'col-4' : 'col-6',
    calculateClassForSmallComponent: ({$mq}) => $mq !== 'xs' ? 'col-2' : 'col-4',
    calculateClassForMediumComponent: ({$mq}) => $mq !== 'xs' ? 'col-3' : 'col-4',
    isEditingApartments: ({editObject}) => Object.keys(editObject).length,
    squareCommonLabel: ({form}) => { 
      switch (form.type) {
        case 'house': return 'Пл. дома';
        case 'flat': return 'Общая';
        default: return 'Пл. М²';
      }
    },
    model: {
      set(val) {
        this.opened = val;
      }
    },
    modelCity: {
      get() {
        return this.form.city
      },
      set(val) {
        this.form.city = val;
        this.handleGetDistrictByCities(val.id);
      }
    },
  },
  methods: {
    ...mapActions(['handleGetObjects', 'handleSortingImagesByApartmentsId', 'handleCreateApartment', 'handleEditObject', 'handleEditApartment', 'handleExportImages', 'handleGetDistrictByCities']),

    async submit() {
      const data = this.dataPreparationForSending(this.form);

      this.errors = apartmentsValidate(data, this.errors);

      this.isError = this.isEmptyError(this.errors);
      if (!this.isError) {
        this.disabledButtonSubmit = true;
        if (this.isEditingApartments) {
          await this.editApartments(data)
        } else {
          await this.createApartments(data)
        }
        this.disabledButtonSubmit = false
      }
    },

    createApartments(data) {
      this.handleCreateApartment(data).then(
        async ({ data, status: statusCode } = {}) => {
          if (statusCode === 201 || statusCode === 204) {
            const message = getMessage(data.status, statusCode);
            this.close();
            await this.submitImages(data);

            this.$notify({
              group: 'app',
              duration: 6000,
              type: 'success',
              text: message
            });
          } else {
            this.$notify({
            group: 'app',
            duration: 6000,
            type: 'error',
            text: data
          });
          }
        })
    },

    editApartments(data) {
      this.handleEditApartment(data).then(
        async ({ data, status: statusCode } = {}) => {
          if (statusCode === 200 || statusCode === 204) {
            const message = getMessage(data.status, statusCode);
            this.close();
            await this.updateSortingImage(data.id)

            this.$notify({
              group: 'app',
              duration: 6000,
              type: 'success',
              text: message
            });
           } else {
            this.$notify({
            group: 'app',
            duration: 6000,
            type: 'error',
            text: data
          });
          }
        }
      )
    },

    updateSortingImage(realtyId) {
      this.handleSortingImagesByApartmentsId({realtyId, images: this.newSorting})
    },

    async submitImages(data) {
      if (this.newImages) {
          await this.handleExportImages({id: data.id, images: this.newImages})
          this.handleGetObjects(data.type)
          this.loading = false;
      }
    },

    dataPreparationForSending(data) {
      const prepareData = JSON.parse(JSON.stringify(data));
      prepareData.labels = data.labels.map(item => item.value)
      prepareData.images = data.images ? data.images : [];
      prepareData.city_id = data.city?.id;
      prepareData.district_id = data.district?.id;
      prepareData.building_id = data.building?.id;
      prepareData.microdistrict = data.microdistrict?.id;
      prepareData.microdistrict_id = data.microdistrict?.id;
      prepareData.street_id = data.street?.id;
      prepareData.status =  this.statusObject ? 'draft' : 'created';
      prepareData.is_cashless_payment_available =  this.cashlessPayments;
      prepareData.is_used_for_advertising = this.statusAdvertising;
      prepareData.is_seller_pays_the_commission = this.statusPaysCommission;

      if (this.isRentFlat) {
        prepareData.is_pets_allowed = this.statusAnimals
        prepareData.is_children_allowed = this.statusChildren
        prepareData.is_foreigners_allowed = this.statusForeigners
      }

      prepareData.repair = data.repair?.id || data.repair
      prepareData.gas = data.gas?.id || data.gas
      prepareData.heating = data.heating?.id || data.heating
      prepareData.sewers = data.sewers?.id || data.sewers
      prepareData.water = data.water?.id || data.water
      // prepareData.currency = data.business_type === 'sell' ? 1 : 2

      prepareData.business_type = data.business_type;

      if (!this.isHouses) {
        delete prepareData.square_area;
      }
      if(this.isCommerce) {
        prepareData.options = data.options
        prepareData.building_id = data.building?.id;
      } else {
        prepareData.residential_complex_id = data.residential_complex?.id;
      }

      return prepareData;
    },

    isEmptyError(errors) {
      return !!Object.values(errors).filter(a => a.length).length
    },

    close() {
      this.model = undefined;
      this.clear()
      this.handleEditObject({});
    },

    clear() {
      this.form = JSON.parse(JSON.stringify(defaultData))
    }
  },
  watch: {
    editObject(val) {
      if (Object.keys(val).length) {
        this.form = JSON.parse(JSON.stringify(val));
        this.statusObject = val.status === 'draft'
        this.statusAdvertising = val.is_used_for_advertising;
        this.statusPaysCommission = val.is_seller_pays_the_commission;
        this.cashlessPayments = val.is_cashless_payment_available
      } else {
        this.clear()
      }
    },
    form: {
      handler(data) {
        prepareValidationFields(data);
          if (this.isError) {
            this.errors = apartmentsValidate(data, this.errors);
          }
      },
      deep: true
    },
  },
};
</script>
