export const validationFields = {
  flat: {
    required: [
      'city',
      'floors',
      'floor',
      'price',
      'street',
      'type',
      'repair',
      'square_common',
      'district',
      'rooms'
    ],
    requiredLength: ['apt_type']
  },
  house: {
    required: ['type', 'district', 'city', 'street', 'repair', 'square_common', 'square_area'],
    requiredLength: []
  },
  commerce: {
    required: [
      'city',
      'price',
      'street',
      'type',
      'square_common',
      'district',
    ],
    requiredLength: ['apt_type']
  },
};
export const validationFieldsClients = {
  flat: {
    required: [
      'city',
      'name',
      'type',
      'priceTo',
    ],
    requiredLength: [
      'rooms',
    ]
  },
  house: {
    required: [
      'city',
      'name',
      'type',
      'priceTo'
    ],
    requiredLength: [
      'rooms',
    ]
  },
};

export const prepareValidationFields = data => {

  const dynamicRequired = {
    residential_complex: data.apt_type === 'new_building',
    microdistrict: data.city && data.city.is_city,
  };

  Object.entries(dynamicRequired).forEach(([key, value]) => {
    if (value) {
      if (!isObjectHasField(key)) {
        validationFields.flat.required.push(key)
      }
    } else {
      removeField(key)
    }
  })
}

const isObjectHasField = field => {
  return validationFields.flat.required.some(a => a === field)
}

const removeField = field => {
  validationFields.flat.required = validationFields.flat.required.filter(a => a !== field)
}

export const apartmentsValidate = (data) => {
  const errors = {
    city: '',
    district: '',
    microdistrict: '',
    street: '',
    square_common: '',
    repair: '',
    apt_type: '',
    square_area: '',
    residential_complex: '',
    floors: '',
    floor: '',
    price: '',
    type: '',
    rooms: ''
  };

  const typeObject = validationFields[data.type || 'flat'];

  Object.entries(data).forEach(([key, value]) => {
    if (typeObject.required.includes(key) && !(data.apt_type === 'farm' && key === 'square_common')) {
      if (!value) {
        errors[key] = 'Поле обязательное для заполения';
      }
      if(key === 'rooms' && (value === 0 || !value)){
        errors.rooms = data.rooms === 0 ? "Выберите количество комнат" : ""
      }
    }

    if (typeObject.requiredLength.includes(key) && !value?.length)
      errors[key] = 'Поле обязательное  для заполения';
  });

  // if(data.apt_type === 'farm' && !data.square_area) {
  //   errors['square_area'] = 'Поле обязательное для заполения';
  // }

  // if (!data.district?.id) {
  //   errors['district'] = 'Поле обязательное для заполения';
  // }

  // if (!data.street?.id) {
  //   errors['street'] = 'Поле обязательное для заполения';
  // }

  // if (data.type === 'house') {
  //   if (!data.apt_type?.length) {
  //     errors['apt_type'] = 'Поле обязательное для заполения';
  //   }
  // }

  // if (data.type === 'house' || data.type === 'flat') {
  //   if (!data.floors?.length) {
  //     errors['floors'] = 'Поле обязательное для заполения';
  //   }
  // }

  // if (!data.price?.length) {
  //   errors['price'] = 'Поле обязательное для заполения';
  // }

  // if (!data.repair?.id) {
  //   errors['repair'] = 'Поле обязательное для заполения';
  // }

  return errors;
};

export const clientValidate = (data, errors) => {
  const typeObject = validationFieldsClients[data.type || 'flat'];
  Object.entries(data).forEach(([key, value]) => {

    if (typeObject.required.includes(key) && !(data.apt_type === 'farm' && key === 'square_common')) {
      if (!value) {
        errors[key] = 'Поле обов\'язкове для заповнення';
      } else {
        errors[key] = ""
      }

    }

      if (typeObject.requiredLength.includes(key) && !value?.length) {
        errors[key] = "Поле обов'язкове для заповнення";
      } else if(typeObject.requiredLength.includes(key) && value?.length) {
        errors[key] = ""
      }

  });

  return errors;
};

export const dataPreparationForSending = (data, isEditing, isHouses) => {
  const prepareData = JSON.parse(JSON.stringify(data));

  prepareData.labels = data.labels.map(item => {
    return item.value
  })
  prepareData.images = data.images ? data.images : [];
  prepareData.district = data.district?.id;
  prepareData.microdistrict = data.microdistrict?.id;
  prepareData.street = data.street?.id;
  prepareData.residential_complex = data.residential_complex?.id;
  if (!isEditing) {
    prepareData.repair = data.repair.id ? data.repair.id : data.repair;
  }
  prepareData.business_type = data.business_type;

  if (isHouses) {
    delete prepareData.square_area;
  }
  return prepareData;
};

export const defaultData = {
  city: null,
  district: [],
  microdistrict: [],
  street: [],
  apt_type: '',
  material_type: '',
  labels: [],
  house_letters: '',
  type: '',
  price: null,
  floors: null,
  floor: null,
  user_label: [],
  house_number: null,
  residential_complex: [],
  house_letter: '',
  square_common: null,
  square_living: null,
  square_kitchen: null,
  text: '',
  images: [],
  options: [],
  house_hull: '',
  repair: '',
  business_type: 'sell',
  square_area: null,
  status: false,
  rooms: 0,
  is_angled: false,
  source: 'rieltor',
}

export const getMessage = status => {
  return status === 'draft'
    ? 'Об\'єкт створено, перейдіть до розділу "мої об\'єкти"'
    : 'Об\'єкт буде доданий до бази після підтвердження його актуальності модераторами сайту! Багато часу це не займе 😊';
};