<template>
  <div
    class="res-modal-wrapp filter-client"
    :class="{ show: this.$parent.showFiltersModal }"
    ref="resFiltersModal"
  >
    <div class="filter-header">
      <div class="filter-head-container p-0">
        <h2 class="filter-main-title" id="page-title">
          {{ this.$route.meta.title }}
        </h2>
        <div class="filter-marg"></div>
        <simplebar
          data-simplebar-auto-hide="false"
          class="simplebar-vertical-only pr-4 pl-2"
          id="search-scroll"
        >
          <item-client
            v-for="(item, i) in groupsClientFilters"
            :key="i"
            v-bind="{ item }"
          />
        </simplebar>
      </div>
    </div>
  </div>
</template>

<script>
import Simplebar from 'simplebar-vue';
import { mapState } from 'vuex';
import ItemClient from '@/components/clients/ItemClient';

export default {
  name: 'ClientFilter',
  components: {
    ItemClient,
    Simplebar
  },
  data() {
    return {
      page: 1
    }
  },
  computed: {
    ...mapState({
      groupsClientFilters: (state) => state.clients.groupsClientFilters,
      clientsCount: (state) => state.clients.count
    })
  },
}
</script>

<style>
.card-user {
  padding: 10px;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #334d66 0%, #354f64 100%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.row-client {
  width: 100%;
  margin: 3px 0;
}
.filter-client {
  padding-right: 0;
  padding-left: 0;
}

.card-user__comment {
  margin: 10px 0;
}
.row-client__name {
  font-weight: 600;
}
.row-client__phone {
  margin: 15px 0 15px 0;
}
.card-search {
  padding: 10px;
  box-shadow: 2px 7px 15px rgba(0, 0, 0, 0.38);
}

.card-search .form-input {
  padding-left: 13px;
}

.card-user .form-input:disabled {
  opacity: 0.6;
}

.client-row {
  margin: 10px 0 20px 0;
}

.client-sub-title {
  display: block;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
}
.pointer-link {
  cursor: pointer;
}
</style>
